<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft rtl" style="display: none;"/>
        <div id="content-wrapper" style="display: none !important;" class="animate__animated animate__fadeInRight">
            <div id="content" class="no-print">
                <header-btn :notifications="notifications"/>
                <!-- <marquee width="100%" direction="right" height="35px" v-if="notifications.length > 0"> 
                    <template v-for="notf in notifications">
                        <div :style="`display: inline; color: ${notf.color};`" :key="'ntf' + notf.notification_id">
                            {{ notf.text }} 
                        </div>
                        <span class="mx-3" :key="'div-ntf' + notf.notification_id"> | </span> 
                    </template>
                    
                </marquee> -->
                <template v-if="user.type == 'E'">
                    <div class="alert alert-info text-center m-1" > Sorry, you can't see this page content </div>
                    <div class="row">
                        <div class="col-xl-4 col-md-4 mb-2 mx-1"> <button class="btn btn-danger btn-block" data-toggle="modal" data-target="#mdl_expired_passports"> {{ indexLanguage[language].expired_passports }} </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2 mx-1"> <button class="btn btn-danger btn-block" data-toggle="modal" data-target="#mdl_expired_accomodations"> Expire Accomodation </button> </div>
                    </div>
                </template>
                <div class="container-fluid" v-else>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark mb-0" id="home_page"> {{ indexLanguage[language].home_page }} </h3>
                    </div>
                    <div class="row" v-if="user.type == 'A'">
                        <div class="col-md-6 col-xl-3 mb-4">
                            <div class="card shadow border-left-primary py-2">
                                <div class="card-body">
                                    <div class="row align-items-center no-gutters">
                                        <div class="col mr-2">
                                            <div class="text-uppercase text-primary font-weight-bold text-xs mb-1 multi-language"><span class="text-capitalize"> {{ indexLanguage[language].number_of_employees }} </span></div>
                                            <hr>
                                            <div class="text-dark font-weight-bold h5 mb-0 multi-language"><span> {{ noOfEmployees }} </span></div>
                                        </div>
                                        <div class="col-1 float-left"><i class="fas fa-user-tie fa-2x text-gray-600"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-4">
                            <div class="card shadow border-left-success py-2">
                                <div class="card-body">
                                    <div class="row align-items-center no-gutters">
                                        <div class="col mr-2">
                                            <div class="text-uppercase text-success font-weight-bold text-xs mb-1 multi-language"><span class="text-capitalize"> {{ indexLanguage[language].number_of_engineers }} </span></div>
                                            <hr>
                                            <div class="text-dark font-weight-bold h5 mb-0 multi-language"><span> {{ engineers.length }} </span></div>
                                        </div>
                                        <div class="col-1 float-left"><i class="fas fa-user-nurse fa-2x text-gray-600"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-4">
                            <div class="card shadow border-left-info py-2">
                                <div class="card-body">
                                    <div class="row align-items-center no-gutters">
                                        <div class="col mr-2">
                                            <div class="text-uppercase text-info font-weight-bold text-xs mb-1 multi-language"><span class="text-capitalize"> {{ indexLanguage[language].number_of_staffs }} </span></div>
                                            <hr>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="text-dark font-weight-bold h5 mb-0 mr-3 multi-language"><span> {{ staffs.length }} </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1 float-left"><i class="fas fa-users fa-2x text-gray-600"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-4">
                            <div class="card shadow border-left-danger py-2">
                                <div class="card-body">
                                    <div class="row align-items-center no-gutters">
                                        <div class="col mr-2">
                                            <div class="text-uppercase text-info font-weight-bold text-xs mb-1 multi-language"><span class="text-capitalize"> {{ indexLanguage[language].number_of_expired_passport }} </span></div>
                                            <hr>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="text-dark font-weight-bold h5 mb-0 mr-3 multi-language"><span> {{ noOfExpired }} </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1 float-left"><i class="fas fa-passport fa-2x text-gray-600"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdl_jobs"> {{ indexLanguage[language].jobs }} </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdl_cabina"> Cabina </button> </div>
                        <div class="col-xl-3 col-md-3 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdl_staffs"> {{ indexLanguage[language].staffs }} </button> </div>
                        <div class="col-xl-1 col-md-1 mb-2" v-if="['A'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdlSortStaff"> <v-icon color="white"> mdi-sort </v-icon> </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdl_emp_staff_history"> {{ indexLanguage[language].employee_staff_history }} </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdl_emp_active_history"> {{ indexLanguage[language].employee_active_history }} </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-warning btn-block" data-toggle="modal" data-target="#mdl_staff_list"> {{ indexLanguage[language].add_staff_list_for_rest_days }} </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#mdl_foods"> {{ indexLanguage[language].foods_number }} </button> </div>
                        <div class="col-xl-2 col-md-4 mb-2 pe-0" v-if="['A', 'U', 'K'].includes(user.type)"> <button style="font-size: 11.7pt !important;" class="btn btn-danger btn-block" data-toggle="modal" data-target="#mdl_expired_accomodations"> Expire Accomodation </button> </div>
                        <div class="col-xl-2 col-md-4 mb-2" v-if="['A', 'U', 'K'].includes(user.type)"> <button class="btn btn-danger btn-block" data-toggle="modal" data-target="#mdl_expired_passports"> Expire Passport </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-success btn-block" data-toggle="modal" data-target="#mdl_transfered_employees"> {{ indexLanguage[language].transfered_employees }} </button> </div>
                        <div class="col-xl-4 col-md-4 mb-2" v-if="['A', 'U'].includes(user.type)"> <button class="btn btn-info btn-block" data-toggle="modal" data-target="#mdl_daily_activity"> Daily Activity </button> </div>
                    </div>
                    <div class="row" style="overflow-x: auto;">
                        <input type="date" class="form-control mb-2 col-xl-9" v-model="list_date">
                        <span class="col-1">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffsforDailyList" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>
                        <table class="table table-bordered table-striped text-dark text-center col-xl-12">
                            <thead>
                                <th style="width: 9%;"> {{ indexLanguage[language].list_id }} </th>
                                <th> {{ indexLanguage[language].staff }} </th>
                                <th> Supervisor </th>
                                <th> {{ indexLanguage[language].user }} </th>
                                <th> {{ indexLanguage[language].location }} </th>
                                <th> {{ indexLanguage[language].note }} </th>
                                <th> Date time </th>
                                <th> {{ indexLanguage[language].detail }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="list in daily_list.filter(obj => obj.special_staff == showSpecialStaffsforDailyList)" :key="list.dsl_id">
                                    <td> {{ list.dsl_id }} </td>
                                    <td> {{ list.staff_name }} </td>
                                    <td> {{ list.supervisor_name }} </td>
                                    <td> {{ list.user }} </td>
                                    <td> {{ list.location }} </td>
                                    <td> {{ list.note }} </td>
                                    <td>
                                        {{ list.datetime_list ? new Date(list.datetime_list).toISOString().split('T')[0] : '' }}
                                        {{ list.datetime_list ? new Date(list.datetime_list).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                                    </td>
                                    <td>
                                        <button class="btn btn-primary mx-1" v-on:click="get_list_details(list.dsl_id, list.st_id, list.datetime_list)" data-toggle="modal" data-target="#mdl_list_detail"> <i class="fa fa-eye"></i> </button>
                                        <button   v-if="user.type == 'A' || user.type == 'K'" class="btn btn-success mx-1" v-on:click="get_list_history(list.dsl_id)" data-toggle="modal" data-target="#mdl_history"> <i class="fa fa-history"></i> </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="px-3" v-if="!printExpires && !printExpiresAccomodation && !printDailyActivity && all_rows.staffs && all_rows.staffs.length > 0 && (user.type == 'A' || user.type == 'K')">
                <span class="pt-3 pb-1 table-only-print" v-if="showSpecialStaffsforDailyList == 'true'">  <h3 > Special Staffs  </h3>  </span>
                <span class="py-3 table-only-print">  Work Date: {{ list_date }} </span>
                <table class="table table-bordered  text-center text-dark table-only-print">
                    <thead>
                        <tr>
                            <th style="color: black; width: 25%;"> Employee </th>
                            <th style="color: black;"> Job </th>
                            <th style="color: black; width: 10%;"> Status </th>
                            <th style="color: black;"> Location </th>
                            <th style="color: black;"> Work </th>
                            <th style="color: black; width: 9%;"> Overtime </th>
                            <th style="color: black; width: 9%;"> Worked </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(staff, index) in all_rows.staffs.filter(obj => obj.special_staff == showSpecialStaffsforDailyList)">
                            <tr :key="'s' + index">
                                <td colspan="7" class="text-start" style="background-color: #EBEBF0 !important;"> 
                                    Staff: {{ staffs.find(obj => obj.st_id == staff.st_id).staff_name }}
                                    
                                    <span style="background-color: #EBEBF0 !important; margin-inline-start: 32px;">
                                        Date time: 
                                        {{ staff.datetime_list ? new Date(staff.datetime_list).toISOString().split('T')[0] : '' }}
                                        {{ staff.datetime_list ? new Date(staff.datetime_list).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                                    </span>
                                </td>
                            </tr>
                            <tr v-for="employee in staff.employees" :key="employee.emp_id">
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ employee.employee_full_name }} </td>
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ employee.job }} </td>
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`" v-bind:class="{ 'text-success': employee.absent == 0, 'text-white': employee.absent == 1, 'text-warning': employee.absent == 2 }"> {{ employee.absent | absent_filter(language) }} </td>
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ employee.location }} </td>
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ employee.work }} </td>
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ employee.overtime }} </td>
                                <td :style="`${employee.absent == '1' ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' && (employee.overtime == 0 && employee.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : employee.absent == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ employee.worked_hours }} </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="px-3" v-if="printExpires && (user.type == 'A' || user.type == 'K')">
                <div class="table-only-print pt-4" v-if="st_id_for_expired"> Staff: {{ staffs.find(obj => obj.st_id == st_id_for_expired).staff_name }}  </div>
                <span class="py-3 table-only-print">  Passports: {{ expire_type == 'less_than_2_month' ? 'Less than 2 month' : expire_type == 'less_than_6_month' ? 'Less than 6 month' : expire_type == 'less_than_1_year' ? 'Less than 1 year' : expire_type == 'less_than_1_and_half_year' ? 'Less than 1 year and 6 month' : 'All expires' }} for {{ active_status_for_expired == '0' ? 'Deactived' : (active_status_for_expired == '1' ? 'Actived' : active_status_for_expired == "" ? "All" : '') }} </span>
                <table class="table table-bordered  text-center text-dark table-only-print">
                    <thead>
                        <th style="width: 30%;"> {{ indexLanguage[language].employee }} </th>
                        <th> Phone </th>
                        <th> {{ indexLanguage[language].staff }} </th>
                        <th> Country </th>
                        <th style="width: 11%;"> Status </th>
                        <th style="width: 23.5%;"> {{ indexLanguage[language].expire_date }} </th>
                    </thead>
                    <tbody>
                        <tr v-for="(employee,index) in filtered_expired_passports.filter(obj => active_status_for_expired ? obj.active_status == active_status_for_expired : true)" v-bind:key="index">
                            <td> {{ employee.full_name }} </td>
                            <td> {{ employee.phone }} </td>
                            <td> {{ employee.staff_name }} </td>
                            <td> {{ employee.country | country_filter }} </td>
                            <td> {{ employee.active_status | status_filter }} </td>
                            <td style="color: black;" class="expired-passport-date" :class="`${diff_days(employee.expire_date) > 180 ? '' : diff_days(employee.expire_date) > 60 ? 'bg-success' : diff_days(employee.expire_date) > 1 ? 'bg-warning' : diff_days(employee.expire_date) <= 0 ? 'bg-danger' : ''}`" > {{ employee.expire_date && isValidDate(employee.expire_date) ? new Date(employee.expire_date).toISOString().split('T')[0] : '' }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="px-3" v-if="printExpiresAccomodation && (user.type == 'A' || user.type == 'K' || user.type == 'E')">
                <div class="table-only-print pt-4" v-if="st_id_for_expired_accomodation"> Staff: {{ staffs.find(obj => obj.st_id == st_id_for_expired_accomodation).staff_name }}  </div>
                <span class="py-3 table-only-print">  Accomodations: {{ expire_type_accomodation == 'less_than_2_month' ? 'Less than 2 month' : expire_type_accomodation == 'less_than_6_month' ? 'Less than 6 month' : 'All expires' }} for {{ active_status_for_expired_accomodation == '0' ? 'Deactived' : (active_status_for_expired_accomodation == '1' ? 'Actived' : active_status_for_expired_accomodation == "" ? "All" : '') }} </span>
                <table class="table table-bordered  text-center text-dark table-only-print">
                    <thead>
                        <th style="width: 30%;"> {{ indexLanguage[language].employee }} </th>
                        <th> Phone </th>
                        <th> {{ indexLanguage[language].staff }} </th>
                        <th> Country </th>
                        <th style="width: 11%;"> Status </th>
                        <th style="width: 23.5%;"> {{ indexLanguage[language].expire_date }} </th>
                    </thead>
                    <tbody>
                        <tr v-for="(employee,index) in filtered_expired_accomodations.filter(obj => active_status_for_expired_accomodation ? obj.active_status == active_status_for_expired_accomodation : true)" v-bind:key="index">
                            <td> {{ employee.full_name }} </td>
                            <td> {{ employee.phone }} </td>
                            <td> {{ employee.staff_name }} </td>
                            <td> {{ employee.country | country_filter }} </td>
                            <td> {{ employee.active_status | status_filter }} </td>
                            <td style="color: black;" class="expired-passport-date" :class="`${diff_days(employee.expire_date) > 180 ? '' : diff_days(employee.expire_date) > 60 ? 'bg-success' : diff_days(employee.expire_date) > 1 ? 'bg-warning' : diff_days(employee.expire_date) <= 0 ? 'bg-danger' : ''}`" > {{ employee.expire_date && isValidDate(employee.expire_date) ? new Date(employee.expire_date).toISOString().split('T')[0] : '' }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="px-3" v-if="printDailyActivity">
                <div class="table-only-print pt-4 pb-3" > Daily Activity
                    <span class="ms-4"> From: {{ new Date(daily_activity_from).toISOString().split('T')[0] }} </span>  
                    <span class="ms-2"> To: {{ new Date(daily_activity_to).toISOString().split('T')[0]}}  </span> 
                </div>
                <span class="pb-3 table-only-print" v-if="daily_activity_user"> 
                    User: {{ daily_activity_user }}
                </span>
                
                <table class="table table-bordered  text-center text-dark table-only-print">
                    <thead>
                        <th> User </th>
                        <th> Staff </th>
                        <th style="width: 11%;"> Work Date </th>
                        <th style="width: 7%; padding-inline: 0;"> List ID </th>
                        <th style="width: 15%;"> Date time </th>
                        <th> Work </th>
                    </thead>
                    <tbody>
                        <tr v-for="(activity, index) in filtered_daily_activities" v-bind:key="'ac' + index">
                            <td> {{ activity.user }} </td>
                            <td> {{ activity.staff_name }} </td>
                            <td :class="{'text-danger': diff_days_between_two_dates(activity.work_date, activity.datetime_log) >= 4 || ((diff_days_between_two_dates(activity.work_date, activity.datetime_log) - 1) < 0  && new Date(activity.datetime_log).toISOString().split('T')[0] != new Date(activity.work_date).toISOString().split('T')[0])}"> {{ new Date(activity.work_date).toISOString().split('T')[0] }} </td>
                            <td style="padding-inline: 0;"> {{ activity.dsl_id }} </td>
                            <td>
                                {{ activity.datetime_log && isValidDate(activity.datetime_log) ? new Date(activity.datetime_log).toISOString().split('T')[0] : '' }}
                                {{ activity.datetime_log && isValidDate(activity.datetime_log) ? new Date(activity.datetime_log).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                            </td>
                            <td dir="rtl"> {{ activity.work }} </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <!-- Modals -->
        <change-pass/>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_jobs">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_job()" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2 jobs"> {{ indexLanguage[language].jobs }} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-3"> {{ indexLanguage[language].id }}: </label>
                                <label class="col-sm-9 form-control disable mt-2"> {{ single_job.job_id }} </label>
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-2"> {{ indexLanguage[language].job_title }}:  </label>
                                <input class="col-sm-9 form-control" type="text" v-model="single_job.job_title">
                            </div>
                            <hr>
                            <div class="row mb-2">
                                <div v-bind:class="{ 'col-sm-6': single_job.job_id != null, 'col-sm-12': single_job.job_id == null }"> 
                                    <button class="btn btn-success btn-block" v-on:click="add_job()"> {{ indexLanguage[language].add }} &nbsp; <i class="fa fa-plus"></i> </button> 
                                </div>
                                <div class="col-sm-6" v-if="single_job.job_id != null"> 
                                    <button class="btn btn-warning btn-block" id="btn_update_job" v-on:click="update_job()"> {{ indexLanguage[language].save_changes }} &nbsp; <i class="fa fa-save"></i> </button>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <input type="text" v-bind:placeholder="indexLanguage[language].search + '...'" class="form-control mb-2" v-model="job_search">
                                <table class="table table-bordered table-striped text-center text-dark">
                                    <thead>
                                        <th style="width: 6%;"> {{ indexLanguage[language].id }} </th>
                                        <th class="job_title"> {{ indexLanguage[language].job_title }} </th>
                                        <th style="width: 30%;"> {{ indexLanguage[language].actions }} </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="job in filtered_jobs" :key="job.job_id">
                                            <td> {{ job.job_id }} </td>
                                            <td> {{ job.job_title }} </td>
                                            <td>
                                                <button class="btn btn-primary mx-1" v-on:click="get_job(job.job_id)"> <i class="fa fa-edit"></i> </button>
                                                <button class="btn btn-danger mx-1" v-on:click="delete_job(job.job_id)"> <i class="fa fa-trash"></i> </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_cabina">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_cabina()" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2 jobs"> Cabina </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-3"> {{ indexLanguage[language].id }}: </label>
                                <label class="col-sm-9 form-control disable mt-2"> {{ single_cabina.cabina_id }} </label>
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-2"> Cabina Name:  </label>
                                <input class="col-sm-9 form-control" type="text" v-model="single_cabina.cabina_name">
                            </div>
                            <hr>
                            <div class="row mb-2">
                                <div v-bind:class="{ 'col-sm-6': single_cabina.cabina_id != null, 'col-sm-12': single_cabina.cabina_id == null }"> 
                                    <button class="btn btn-success btn-block" v-on:click="add_cabina()"> {{ indexLanguage[language].add }} &nbsp; <i class="fa fa-plus"></i> </button> 
                                </div>
                                <div class="col-sm-6" v-if="single_cabina.cabina_id != null"> 
                                    <button class="btn btn-warning btn-block" id="btn_update_cabina" v-on:click="update_cabina()"> {{ indexLanguage[language].save_changes }} &nbsp; <i class="fa fa-save"></i> </button>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <input type="text" v-bind:placeholder="indexLanguage[language].search + '...'" class="form-control mb-2" v-model="cabina_search">
                                <table class="table table-bordered table-striped text-center text-dark">
                                    <thead>
                                        <th style="width: 6%;"> {{ indexLanguage[language].id }} </th>
                                        <th class="job_title"> Cabina Name </th>
                                        <th style="width: 30%;"> {{ indexLanguage[language].actions }} </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cabina in filtered_cabinas" :key="cabina.cabina_id">
                                            <td> {{ cabina.cabina_id }} </td>
                                            <td> {{ cabina.cabina_name }} </td>
                                            <td>
                                                <button class="btn btn-primary mx-1" v-on:click="get_cabina(cabina.cabina_id)"> <i class="fa fa-edit"></i> </button>
                                                <button class="btn btn-danger mx-1" v-on:click="delete_cabina(cabina.cabina_id)"> <i class="fa fa-trash"></i> </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_staffs">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_staff()" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].staffs }} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-2"> {{ indexLanguage[language].id }}:  </label>
                                <label class="col-sm-9 form-control disable mt-2"> {{ single_staff.st_id }} </label>
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-2"> {{ indexLanguage[language].supervisor }}:  </label>
                                <select class="col-sm-9 form-control" v-model="single_staff.en_id">
                                    <option value=""></option>
                                    <option v-for="engineer in engineers" :key="engineer.en_id" v-bind:value="engineer.en_id"> {{ engineer.full_name }} </option>
                                </select>
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-2"> {{ indexLanguage[language].staff_name }}:  </label>
                                <input class="col-sm-9 form-control" type="text" placeholder="Enter name of the staff" v-model="single_staff.staff_name">
                            </div>
                            <hr>
                            <div class="row mb-2">
                                <div v-bind:class="{ 'col-sm-6': single_staff.st_id != null, 'col-sm-12': single_staff.st_id == null }"> 
                                    <button class="btn btn-success btn-block" id="btn_add_staff" v-on:click="add_staff()"> {{ indexLanguage[language].add }} &nbsp; <i class="fa fa-plus"></i> </button> 
                                </div>
                                <div class="col-sm-6" v-if="single_staff.st_id != null"> 
                                    <button class="btn btn-warning btn-block" id="btn_update_staff" v-on:click="update_staff()"> {{ indexLanguage[language].save_changes }} &nbsp; <i class="fa fa-save"></i> </button> 
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <input type="text" v-bind:placeholder="indexLanguage[language].search + '...'" class="form-control col-9 mb-2" v-model="staff_search">
                                <span class="col-1">
                                    <v-checkbox class="mt-0" v-model="showSpecialStaffs" true-value="true" false-value="false"></v-checkbox>
                                </span>
                                <span class="col-2" style="margin-top: 6px; margin-inline-start: -29px;">
                                    Specials
                                </span>
                                <table class="table table-bordered table-striped text-center text-dark">
                                    <thead>
                                        <th style="width: 6%;"> {{ indexLanguage[language].id }} </th>
                                        <th> {{ indexLanguage[language].staff_name }} </th>
                                        <th style="width: 20%;"> Special </th>
                                        <th style="width: 30%;"> {{ indexLanguage[language].actions }} </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="staff in filtered_staffs" v-bind:key="staff.st_id">
                                            <td> {{ staff.st_id }} </td>
                                            <td> {{ staff.staff_name }} </td>
                                            <td>
                                                <v-checkbox v-model="staff.special_staff" @change="updateSpecialStaff(staff.st_id, staff.special_staff)" true-value="true" false-value="false" class="mt-0 justify-center-checkbox">  </v-checkbox>
                                            </td>
                                            <td>
                                                <button class="btn btn-primary mx-1" v-on:click="get_staff(staff.st_id)" > <i class="fa fa-edit"></i> </button>
                                                <button class="btn btn-danger mx-1" v-on:click="delete_staff(staff.st_id)"> <i class="fa fa-trash"></i> </button>
                                                <button class="btn btn-success mx-1" v-if="staff.show_staff == 1" v-on:click="show_off(staff.st_id)"> <i class="fa fa-eye-slash"></i> </button>
                                                <button class="btn btn-danger mx-1" v-else v-on:click="show_on(staff.st_id)"> <i class="fa fa-eye"></i> </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_emp_staff_history">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].employee_staff_history }} </h5>
                    </div>
                    <div class="modal-body">
                        <input type="text" v-bind:placeholder="indexLanguage[language].search + '...'" class="form-control mb-2 col-xl-3 float-left" v-on:keyup.enter="search_staff_log()" v-model="staff_log_search_value">
                        <button class="btn btn-primary mx-2 mb-2" v-on:click="get_limited_data_staff_log(1)"> <i class="fa fa-redo"></i> </button>
                        <table class="table table-responsive table-bordered table-striped text-dark text-center">
                            <thead>
                                <th style="width: 8%;"> {{ indexLanguage[language].id }} </th>
                                <th> {{ indexLanguage[language].employee }} </th>
                                <th> {{ indexLanguage[language].old_staff }} </th>
                                <th> {{ indexLanguage[language].new_staff }} </th>
                                <th style="width: 15%;"> {{ indexLanguage[language].change_date }} </th>
                                <th style="width: 5%;"> {{ indexLanguage[language].delete }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="log in staff_change_log" :key="log.sle_id">
                                    <td> {{ log.sle_id }} </td>
                                    <td> {{ log.full_name }} </td>
                                    <td> {{ log.old_staff }} </td>
                                    <td> {{ log.new_staff }} </td>
                                    <td> {{ log.change_date.slice(0, 10) }} </td>
                                    <td> 
                                        <button class="btn btn-danger" v-on:click="delete_staff_log(log.sle_id)"> <i class="fa fa-trash"></i> </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        <div id="pagination_staff_change"> 
                            <button class="btn btn-primary" v-on:click="previous_pagination_staff_log()"> &lt; </button>

                            <button class="btn btn-outline-primary mx-1 pagination-btns-staff-log"
                                    style="display: none;"
                                    v-for="num in Math.ceil(noOfStaffLog / 20)" :key="num"
                                    v-bind:class=" 'pg-staff-log-group-' + Math.ceil(num / 5) "
                                    v-on:click="get_limited_data_staff_log(num)"> {{ num }} </button>
                            
                            <button class="btn btn-primary" v-on:click="next_pagination_staff_log()"> &gt; </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_emp_active_history">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].employee_active_history }} </h5>
                    </div>
                    <div class="modal-body">
                        <input type="text" v-bind:placeholder="indexLanguage[language].search + '...'" class="form-control mb-2 col-xl-3 float-left" v-on:keyup.enter="active_log_search()" v-model="active_log_search_value">
                        <button class="btn btn-primary mx-2 mb-2" v-on:click="get_limited_data_active_log(1)"> <i class="fa fa-redo"></i> </button>
                        <table class="table table-responsive table-bordered table-striped text-dark text-center">
                            <thead>
                                <th style="width: 10%;"> {{ indexLanguage[language].id }} </th>
                                <th> {{ indexLanguage[language].employee }} </th>
                                <th style="width: 15%;"> {{ indexLanguage[language].change_type }} </th>
                                <th style="width: 15%;"> {{ indexLanguage[language].change_date }} </th>
                                <th style="width: 5%;"> {{ indexLanguage[language].delete }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="log in active_change_log" :key="log.ale_id">
                                    <td> {{ log.ale_id }} </td>
                                    <td> {{ log.full_name }} </td>
                                    <td v-bind:class="{ 'alert-success': log.change_type == 'Set to Active', 'alert-danger': log.change_type == 'Set to Deactive' }"> {{ log.change_type }} </td>
                                    <td> {{ log.change_date.slice(0, 10) }} </td>
                                    <td> 
                                        <button class="btn btn-danger" v-on:click="delete_active_log(log.ale_id)"> <i class="fa fa-trash"></i> </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="pagination_active_change"> 
                            <button class="btn btn-primary" v-on:click="previous_pagination_active_log()"> &lt; </button>

                            <button class="btn btn-outline-primary mx-1 pagination-btns-active-log"
                                    style="display: none;"
                                    v-for="num in Math.ceil(noOfActiveLog / 20)" :key="num"
                                    v-bind:class=" 'pg-active-log-group-' + Math.ceil(num / 5) "
                                    v-on:click="get_limited_data_active_log(num)"> {{ num }} </button>
                            
                            <button class="btn btn-primary" v-on:click="next_pagination_active_log()"> &gt; </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_staff_list" v-if="['A', 'U'].includes(user.type)">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                    </div>
                    <div class="modal-body">
                        <input type="date" class="form-control mb-2" v-model="list_rest_days.work_date">
                        <button class="btn btn-success btn-block" id="btn_rest_day" v-on:click="create_list_rest_day()"> {{ indexLanguage[language].create_list_for_all_staffs }} &nbsp; <i class="fa fa-plus"></i> </button>
                        <hr>
                        <div class="alert alert-primary text-center" id="waiting" style="display: none;"> {{ indexLanguage[language].waiting }} ... </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_list_detail">
            <div class="modal-dialog modal-xl text-center">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].list_detail }} </h5>
                    </div>
                    <div class="modal-body" style="overflow-x: auto;">
                        <div style="color: black; margin-bottom: 8px; text-align: left;"> Date time: {{ datetime }} </div>
                        <table class="table table-bordered table-striped text-dark"> 
                            <thead>
                                <th style="width: 27%;"> {{ indexLanguage[language].employee }} </th>
                                <th> Job </th>
                                <th style="width: 9%;"> {{ indexLanguage[language].status }} </th>
                                <th> {{ indexLanguage[language].location }} </th>
                                <th style="width: 9%;"> {{ indexLanguage[language].overtime }} </th>
                                <th style="width: 12%;"> {{ indexLanguage[language].worked_hours }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="emp in filtered_list_detail" :key="emp.at_id" v-bind:class="{ 'bg-warning text-white': emp.st_id != st_id }">
                                    <td :style="`${emp.status == '1' ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2'&& (emp.overtime == 0 && emp.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ emp.full_name }} </td>
                                    <td :style="`${emp.status == '1' ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2'&& (emp.overtime == 0 && emp.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ emp.job }} </td>
                                    <td :style="`${emp.status == '1' ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2'&& (emp.overtime == 0 && emp.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`" v-bind:class="{ 'text-success': emp.status == 0, 'text-white': emp.status == 1, 'text-warning': emp.status == 2 }"> {{ emp.status | absent_filter(language) }} </td>
                                    <td :style="`${emp.status == '1' ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2'&& (emp.overtime == 0 && emp.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ emp.location }} </td>
                                    <td :style="`${emp.status == '1' ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2'&& (emp.overtime == 0 && emp.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`"> {{ emp.overtime }} </td>
                                    <td :style="`${emp.status == '1' ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2'&& (emp.overtime == 0 && emp.worked_hours == 0) ? 'background-color: #F24C3D !important; color: white !important;' : emp.status == '2' ? 'background-color: #F4b619 !important; color: black !important;' : ''}`" :class="{'text-danger': emp.status == 0 && emp.worked_hours == 0}"> {{ emp.worked_hours }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_foods">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].foods_number }} </h5>
                    </div>
                    <div class="modal-body">
                        
                        <table class="table table-bordered table-striped text-dark"> 
                            <thead>
                                <th> {{ indexLanguage[language].staff }} </th>
                                <th> {{ indexLanguage[language].location }} </th>
                                <th style="width: 20%;"> {{ indexLanguage[language].foods_number }} </th>
                                <th style="width: 25%;"> Food Group </th>
                                <th > Date Time </th>
                            </thead>
                            <tbody>
                                <tr v-for="(food, index) in staff_foods" v-bind:key="index" v-bind:class="{ 'bg-warning text-white': food.food_number == 0 }">
                                    <td> {{ food.staff_name }} </td>
                                    <td> {{ food.location }} </td>
                                    <td> {{ food.food_number }} </td>
                                    <td> {{ food.food_group }} </td>
                                    <td>
                                        {{ food.datetime_food ? new Date(food.datetime_food).toISOString().split('T')[0] : '' }}
                                        {{ food.datetime_food ? new Date(food.datetime_food).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        <table class="table table-bordered table-striped text-dark"> 
                            <thead>
                                <th class="bg-success text-light"> {{ indexLanguage[language].total }} </th>
                                <th class="bg-success text-light" style="width: 70%;"> {{ total_foods }} </th>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_expired_passports">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].expired_passports }} </h5>
                        <button class="btn btn-primary" data-dismiss="modal" @click="printExpirePassports()"> Print </button>

                    </div>
                    <div class="modal-body">
                        <select class="form-control" v-model="st_id_for_expired">
                            <option value=""></option>
                            <option v-for="staff in staffs" :key="staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <select class="form-control mt-3" v-model="active_status_for_expired">
                            <option value="" v-if="user.type != 'E'"> All </option>
                            <option value="1"> Actived </option>
                            <option value="0" v-if="user.type != 'E'"> Deactived </option>
                        </select>
                        <select class="form-control mt-3" v-model="expire_type">
                            <option value="all" v-if="user.type != 'E'"> All expires </option>
                            <option value="less_than_1_and_half_year"> Less than 1 year, 6 month </option>
                            <option value="less_than_1_year" v-if="user.type != 'E'"> Less than 1 year </option>
                            <option value="less_than_6_month" v-if="user.type != 'E'"> Less than 6 month </option>
                            <option value="less_than_2_month" v-if="user.type != 'E'"> Less than 2 month </option>
                        </select>
                        <table class="table table-bordered table-striped text-dark text-center mt-5"> 
                            <thead>
                                <th style="width: 30%;"> {{ indexLanguage[language].employee }} </th>
                                <th> Phone </th>
                                <th> {{ indexLanguage[language].staff }} </th>
                                <th> Country </th>
                                <th style="width: 11%;"> Status </th>
                                <th style="width: 23.5%;"> {{ indexLanguage[language].expire_date }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="(employee,index) in filtered_expired_passports.filter(obj => active_status_for_expired ? obj.active_status == active_status_for_expired : true)" v-bind:key="index">
                                    <td> {{ employee.full_name }} </td>
                                    <td> {{ employee.phone }} </td>
                                    <td> {{ employee.staff_name }} </td>
                                    <td> {{ employee.country | country_filter }} </td>
                                    <td> {{ employee.active_status | status_filter }} </td>
                                    <td style="color: black;" class="expired-passport-date" :class="`${diff_days(employee.expire_date) > 180 ? '' : diff_days(employee.expire_date) > 60 ? 'bg-success' : diff_days(employee.expire_date) > 1 ? 'bg-warning' : diff_days(employee.expire_date) <= 0 ? 'bg-danger' : ''}`" > {{ employee.expire_date && isValidDate(employee.expire_date) ? new Date(employee.expire_date).toISOString().split('T')[0] : '' }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_expired_accomodations">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> Expired Accomodations </h5>
                        <button class="btn btn-primary" data-dismiss="modal" @click="printExpireAccomodations()"> Print </button>

                    </div>
                    <div class="modal-body">
                        <select class="form-control" v-model="st_id_for_expired_accomodation">
                            <option value=""></option>
                            <option v-for="staff in staffs" :key="staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <select class="form-control mt-3" v-model="active_status_for_expired_accomodation">
                            <option value="" v-if="user.type != 'E'"> All </option>
                            <option value="1"> Actived </option>
                            <option value="0" v-if="user.type != 'E'"> Deactived </option>
                        </select>
                        <select class="form-control mt-3" v-model="expire_type_accomodation">
                            <option value="all"> All expires </option>
                            <option value="less_than_6_month"> Less than 6 month </option>
                            <option value="less_than_2_month"> Less than 2 month </option>
                        </select>
                        <table class="table table-bordered table-striped text-dark text-center mt-5"> 
                            <thead>
                                <th style="width: 30%;"> {{ indexLanguage[language].employee }} </th>
                                <th> Phone </th>
                                <th> {{ indexLanguage[language].staff }} </th>
                                <th> Country </th>
                                <th style="width: 11%;"> Status </th>
                                <th style="width: 23.5%;"> {{ indexLanguage[language].expire_date }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="(employee,index) in filtered_expired_accomodations.filter(obj => active_status_for_expired_accomodation ? obj.active_status == active_status_for_expired_accomodation : true)" v-bind:key="index">
                                    <td> {{ employee.full_name }} </td>
                                    <td> {{ employee.phone }} </td>
                                    <td> {{ employee.staff_name }} </td>
                                    <td> {{ employee.country | country_filter }} </td>
                                    <td> {{ employee.active_status | status_filter }} </td>
                                    <td style="color: black;" class="expired-passport-date" :class="`${diff_days(employee.expire_date) > 180 ? '' : diff_days(employee.expire_date) > 60 ? 'bg-success' : diff_days(employee.expire_date) > 1 ? 'bg-warning' : diff_days(employee.expire_date) <= 0 ? 'bg-danger' : ''}`" > {{ employee.expire_date && isValidDate(employee.expire_date) ? new Date(employee.expire_date).toISOString().split('T')[0] : '' }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_transfered_employees">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ indexLanguage[language].transfered_employees }} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <label class="col-sm-1 mt-2"> {{ indexLanguage[language].year }}: </label>
                            <input type="number" class="form-control col-sm-1" v-model="transfered_employees.year">

                            <label class="col-sm-1 mt-2"> {{ indexLanguage[language].month }}: </label>
                            <select class="form-control col-sm-1" v-model="transfered_employees.month">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>

                            <label class="col-sm-2 mt-2 ml-3"> {{ indexLanguage[language].from_staff }}: </label>
                            <select class="form-control col-sm-3" v-model="transfered_employees.old_st_id">
                                <option value=""></option>
                                <option v-for="staff in staffs" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                            </select>
                        </div>
                        <hr>
                        <table class="table table-bordered table-striped text-dark text-center"> 
                            <thead>
                                <th style="width: 30%;"> {{ indexLanguage[language].employee }} </th>
                                <th style="width: 23.5%;"> {{ indexLanguage[language].new_staff }} </th>
                                <th> {{ indexLanguage[language].work_date }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="employee in transfered_employees.employees" :key="employee.t_at_id">
                                    <td> {{ employee.employee }} </td>
                                    <td> {{ employee.staff_name }} </td>
                                    <td> {{ new Date(employee.work_date).toISOString().split("T")[0] }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_history">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> List History </h5>
                    </div>
                    <div class="modal-body">
                        <v-data-table :items-per-page="20" :headers="history_headers" :footer-props="{'items-per-page-options': [15, 20, 25]}" :items="history_rows.filter(obj => obj.dsl_id == dsl_id)">
                            <template v-slot:[`item.work`]="{item}">
                                <div dir="rtl" style="white-space: pre;">{{item.work}}</div>
                            </template>
                            <template v-slot:[`item.datetime_log`]="{item}">
                                {{ item.datetime_log && isValidDate(item.datetime_log) ? new Date(item.datetime_log).toISOString().split('T')[0] : '' }}
                                {{ item.datetime_log && isValidDate(item.datetime_log) ? new Date(item.datetime_log).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_daily_activity">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> Daily Activity </h5>
                        <button class="btn btn-primary" data-dismiss="modal" @click="printDailyActivityData()"> Print </button>
                    </div>
                    <div class="modal-body">
                        <label class="me-2"> From: </label>
                        <input type="date" style="display: inline" class="form-control mb-2 col-xl-3" v-model="daily_activity_from">
                        <label class="ms-4 me-2"> To: </label>
                        <input type="date" style="display: inline" class="form-control mb-2 col-xl-3" v-model="daily_activity_to">
                        <select class="form-control mb-2 col-xl-12" v-model="daily_activity_user">
                            <option value=""></option>
                            <option v-for="user in daily_activity_users" :key="user" :value="user"> {{ user }} </option>
                        </select>
                        حەکیم عومەر <input type="checkbox" v-model="except_daily_activity_user">
                        <span class="ms-3"> Warning Dates </span> <input type="checkbox" v-model="warning_dates">
                        <v-data-table :items-per-page="20" :headers="daily_activity_headers" :footer-props="{'items-per-page-options': [15, 20, 25]}" :items="filtered_daily_activities">
                            <template v-slot:[`item.work`]="{item}">
                                <div dir="rtl" style="white-space: pre;">{{item.work}}</div>
                            </template>

                            <template v-slot:[`item.datetime_log`]="{item}">
                                {{ item.datetime_log && isValidDate(item.datetime_log) ? new Date(item.datetime_log).toISOString().split('T')[0] : '' }}
                                {{ item.datetime_log && isValidDate(item.datetime_log) ? new Date(item.datetime_log).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                            </template>

                            <template v-slot:[`item.work_date`]="{item}">
                                <span :class="{'text-danger': diff_days_between_two_dates(item.work_date, item.datetime_log) >= 4 || ((diff_days_between_two_dates(item.work_date, item.datetime_log) - 1) < 0  && new Date(item.datetime_log).toISOString().split('T')[0] != new Date(item.work_date).toISOString().split('T')[0])}">  {{ new Date(item.work_date).toISOString().split('T')[0] }} </span>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlSortStaff">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal">&times;</button>
                        <h5 class="mt-2"> Sort Staff <button class="btn btn-primary ms-2" @click="saveStaffSort()"> <v-icon color="white">  mdi-content-save </v-icon> </button> </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div style="width: 100%;">
                                <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSPecialStaffsForSort" true-value="true" false-value="false" label="Specials"></v-checkbox>
                                <draggable v-model="staff_to_sort">
                                    <transition-group>
                                        <v-btn block color="primary" elevation="0" class="mb-2" v-for="(staff, i) in staff_to_sort" :key="'emp' + i" > {{ staff.staff_name }} </v-btn>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    
    import showError from "../showError.js";
    import auth from "../auth.js";
    import $ from "jquery";
    import indexLanguage from "../languages/index.js";
    import { Bus } from "../Bus.js";
    import draggable from 'vuedraggable'
    const nullValues = ["", null];

    export default {
        name: "Index",
        components: {
            draggable
        },
        data(){
            return {
                all_rows: {},
                noOfEmployees: 0,
                noOfStaffLog: 0,
                noOfActiveLog: 0,
                noOfExpired: 0,
                max_pagination_btns_staff_change: 0,
                current_pagination_btns_staff_change: 1,
                max_pagination_btns_active_change: 0,
                current_pagination_btns_active_change: 1,
                list_date: new Date().toISOString().split("T")[0],
                jobs: [],
                staffs: [],
                staff_to_sort: [],
                single_job: {
                    job_id: null,
                    job_title: ""
                },
                job_search: "",
                single_cabina: {
                    cabina_id: null,
                    cabina_name: ""
                },
                cabinas: [],
                cabina_search: "",
                engineers: [],
                single_staff: {
                    st_id: null,
                    en_id: null,
                    staff_name: ""
                },
                staff_search: "",
                staff_change_log: [],
                active_change_log: [],
                staff_log_search_value: "",
                active_log_search_value: "",
                daily_list: [],
                list_details: [],
                dsl_id: null,
                st_id: null,
                list_rest_days: {
                    work_date: new Date().toISOString().split("T")[0]
                },
                staff_foods: [],
                total_foods: 0,
                expired_passports: [],
                expired_accomodations: [],
                user: {
                    type: '',
                    username: "",
                    en_id: ""
                },
                transfered_employees: {
                    month: new Date().getMonth(),
                    year: new Date().getFullYear(),
                    old_st_id: null,
                    employees: []
                },
                indexLanguage,
                language: "English",
                history_headers: [
                    {
                        text: 'User',
                        align: 'start',
                        value: 'user',
                        sortable: false,
                    },
                    {
                        text: 'Date time',
                        align: 'start',
                        value: 'datetime_log',
                        sortable: false,
                    },
                    {
                        text: 'Work',
                        align: 'center',
                        value: 'work',
                        sortable: false,
                    },
                ],
                daily_activity_headers: [
                    {
                        text: 'User',
                        align: 'start',
                        value: 'user',
                        sortable: false,
                    },
                    {
                        text: 'Staff',
                        align: 'start',
                        value: 'staff_name',
                        sortable: false,
                    },
                    {
                        text: 'Work Date',
                        align: 'start',
                        value: 'work_date',
                        width: '120px',
                        sortable: true,
                    },
                    {
                        text: 'List ID',
                        align: 'start',
                        value: 'dsl_id',
                        sortable: false,
                    },
                    {
                        text: 'Date time',
                        align: 'start',
                        value: 'datetime_log',
                        sortable: true,
                    },
                    {
                        text: 'Work',
                        align: 'center',
                        value: 'work',
                        sortable: false,
                    },
                ],
                history_rows: [],
                daily_activity_from: new Date().toISOString().split("T")[0],
                daily_activity_to: new Date().toISOString().split("T")[0],
                daily_activity_rows: [],
                daily_activity_users: [],
                daily_activity_user: null,
                st_id_for_expired: null,
                st_id_for_expired_accomodation: null,
                active_status_for_expired: '',
                active_status_for_expired_accomodation: '',
                expire_type: 'less_than_2_month',
                expire_type_accomodation: 'less_than_2_month',
                except_daily_activity_user: false,
                is_print: false,
                showSpecialStaffs: "false",
                showSpecialStaffsforDailyList: "false",
                showSPecialStaffsForSort: "false",
                datetime: '',
                printExpires: false,
                printExpiresAccomodation: false,
                printDailyActivity: false, 
                notifications: [],
                warning_dates: false
            }
        },
        beforeCreate(){
            auth("index");
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user.username = data.username;
                this.user.type = data.type;
                this.user.en_id = data.en_id;
                if(this.user.en_id) {
                    this.$http.post("/staff/getByEngineer/" + this.user.en_id).then(({data}) => {
                        this.staffs = data;
                    }).then(() => {
                        this.active_status_for_expired_accomodation = '1'
                        this.expire_type = 'less_than_1_and_half_year'
                        this.active_status_for_expired = '1'
                        this.$http.post("/expirePassport/getData").then(({data}) => {
                            this.expired_passports = data;
                            this.expired_passports = this.expired_passports.filter(obj => this.staffs.map(obj => obj.st_id).includes(obj.st_id))
                        });
                        this.$http.post("/expireAccomodation/getData").then(({data}) => {
                            this.expired_accomodations = data;
                            this.expired_accomodations = this.expired_accomodations.filter(obj => this.staffs.map(obj => obj.st_id).includes(obj.st_id))
                        });
                    });
                } else {
                    this.$http.post("/staff/getData").then(({data}) => {
                        this.staffs = data.sort((a, b) => a.staff_sort_code - b.staff_sort_code).sort((a, b) => Number(b.show_staff) - Number(a.show_staff))
                        this.staff_to_sort = data.filter(obj => obj.special_staff == "false" && obj.show_staff == 1).sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                    }).then(() => {
                        this.$http.post("/expirePassport/getData").then(({data}) => {
                            this.expired_passports = data;
                        });
                        this.$http.post("/expireAccomodation/getData").then(({data}) => {
                            this.expired_accomodations = data;
                            this.expired_accomodations = this.expired_accomodations.filter(obj => this.staffs.map(obj => obj.st_id).includes(obj.st_id))
                        });
                    });
                }

                this.$http.post("/index/", {
                    user_id: data.user_id
                }).then(({data}) => {
                    this.noOfEmployees = data.noOfEmployees;
                    this.noOfStaffLog = data.noOfStaffLog;
                    this.noOfActiveLog = data.noOfActiveLog;
                    this.noOfExpired = data.noOfExpired;
                    this.cabinas = data.cabinas;
                    this.notifications = data.notifications;

                    var btns_staff_log = Math.ceil(this.noOfStaffLog / 20);
                    var staff_log_groups = Math.ceil(btns_staff_log / 5);
                    this.max_pagination_btns_staff_change = staff_log_groups;

                    var btns_active_log = Math.ceil(this.noOfActiveLog / 20);
                    var active_log_groups = Math.ceil(btns_active_log / 5);
                    this.max_pagination_btns_active_change = active_log_groups;

                });
            });
            this.$http.post("/job/getData").then(({data}) => {
                this.jobs = data;
            });
            
            this.$http.post("/engineer/getNames").then(({data}) => {
                this.engineers = data;
            });

            this.$http.post("/daily_staff_list/getDailyList", {
                work_date: this.list_date
            }).then(({data}) => {
                this.daily_list = data.lists;
                this.list_details = data.list_details;
                this.history_rows = data.list_history;
                this.all_rows = data.all_rows;
            });

            this.$http.post("/daily_staff_list/getFoods", {
                work_date: this.list_date
            }).then(({data}) => {
                this.staff_foods = data.staff_foods;
                this.total_foods = data.total_foods;
            });
            

            setTimeout(() => {
                this.get_limited_data_active_log(1);
                this.get_limited_data_staff_log(1);
            }, 6000)

            window.matchMedia('print').addListener(e => {
                if(e.matches) {
                    this.is_print = true;
                } else {
                    this.is_print = false;
                }
            });
        },
        mounted() {
            var load_pagination_staff_log = setInterval(() => {
                var pagination_btns_staff_log = document.getElementsByClassName('pg-staff-log-group-1');
                if(pagination_btns_staff_log.length > 0){
                    for(let i=0; i<pagination_btns_staff_log.length; i++){
                        pagination_btns_staff_log[i].style.display = 'inline';
                    }
                    clearInterval(load_pagination_staff_log);
                }
            }, 750);

            var load_pagination_active_log = setInterval(() => {
                var pagination_btns_active_log = document.getElementsByClassName('pg-active-log-group-1');
                if(pagination_btns_active_log.length > 0){
                    for(let i=0; i<pagination_btns_active_log.length; i++){
                        pagination_btns_active_log[i].style.display = 'inline';
                    }
                    clearInterval(load_pagination_active_log);
                }
            }, 750);

            setTimeout(() => {
                this.$http.get('/daily_staff_list/getLogViewByDate/' + this.daily_activity_from + '/' + this.daily_activity_to).then(({data}) => {
                    this.daily_activity_rows = data.rows
                    this.daily_activity_users = data.users.map(obj => obj.user)
                })
            }, 2000)

            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl")
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr")
                }
            });
        },
        methods: {
            previous_pagination_staff_log(){
                if(this.max_pagination_btns_staff_change > 1){
                    var btns_to_hide = document.getElementsByClassName('pg-staff-log-group-' + this.current_pagination_btns_staff_change);
                    for(let i = 0; i < btns_to_hide.length; i++){
                        btns_to_hide[i].style.display = 'none';
                    }

                    --this.current_pagination_btns_staff_change;

                    var btns_to_show = document.getElementsByClassName('pg-staff-log-group-' + this.current_pagination_btns_staff_change);
                    for(let i = 0; i < btns_to_show.length; i++){
                        btns_to_show[i].style.display = 'inline';
                    }
                }
            },
            next_pagination_staff_log(){
                if(this.max_pagination_btns_staff_change < this.current_pagination_btns_staff_change){
                    var btns_to_hide = document.getElementsByClassName('pg-staff-log-group-' + this.current_pagination_btns_staff_change);
                    for(let i = 0; i < btns_to_hide.length; i++){
                        btns_to_hide[i].style.display = 'none';
                    }

                    ++this.current_pagination_btns_staff_change;

                    var btns_to_show = document.getElementsByClassName('pg-staff-log-group-' + this.current_pagination_btns_staff_change);
                    for(let i = 0; i < btns_to_show.length; i++){
                        btns_to_show[i].style.display = 'inline';
                    }
                }
            },
            previous_pagination_active_log(){
                if(this.max_pagination_btns_active_change > 1){
                    var btns_to_hide = document.getElementsByClassName('pg-active-log-group-' + this.current_pagination_btns_active_change);
                    for(let i = 0; i < btns_to_hide.length; i++){
                        btns_to_hide[i].style.display = 'none';
                    }

                    --this.current_pagination_btns_active_change;

                    var btns_to_show = document.getElementsByClassName('pg-active-log-group-' + this.current_pagination_btns_active_change);
                    for(let i = 0; i < btns_to_show.length; i++){
                        btns_to_show[i].style.display = 'inline';
                    }
                }
            },
            next_pagination_active_log(){
                if(this.max_pagination_btns_active_change < this.current_pagination_btns_active_change){
                    var btns_to_hide = document.getElementsByClassName('pg-active-log-group-' + this.current_pagination_btns_active_change);
                    for(let i = 0; i < btns_to_hide.length; i++){
                        btns_to_hide[i].style.display = 'none';
                    }

                    ++this.current_pagination_btns_active_change;

                    var btns_to_show = document.getElementsByClassName('pg-active-log-group-' + this.current_pagination_btns_active_change);
                    for(let i = 0; i < btns_to_show.length; i++){
                        btns_to_show[i].style.display = 'inline';
                    }
                }
            },
            get_limited_data_staff_log(paginate_number) {
                var offset = 0;
                if(paginate_number > 1){
                    offset = (paginate_number - 1) * 20;
                }
                this.$http.post("/index/getStaffLogChange", { offset }).then(({data}) => {
                    this.staff_change_log = data;
                });
            },
            delete_staff_log(sle_id) {
                this.$confirm("Are you sure delete this data ?", "", "question").then(() =>{
                    this.$http.delete("/index/deleteStaffLog/" + sle_id).then(() => {
                        this.staff_change_log = this.staff_change_log.filter(obj => obj.sle_id != sle_id);
                        this.staff_log_search_value = "";
                    });
                });
            },
            get_limited_data_active_log(paginate_number) {
                var offset = 0;
                if(paginate_number > 1){
                    offset = (paginate_number - 1) * 20;
                }
                this.$http.post("/index/getActiveLog", { offset }).then(({data}) => {
                    this.active_change_log = data;
                });
            },
            delete_active_log(ale_id) {
                this.$confirm("Are you sure delete this data ?", "", "question").then(() => {
                    this.$http.delete("/index/deleteActiveLog/" + ale_id).then(() => {
                        this.active_change_log = this.active_change_log.filter(obj => obj.ale_id != ale_id);
                        this.active_log_search_value = "";
                    });
                });
            },
            get_job(job_id) {
                const [job] = this.jobs.filter(obj => obj.job_id == job_id);
                this.single_job.job_id = job.job_id;
                this.single_job.job_title = job.job_title;
            },
            clear_job() {
                this.single_job.job_id = null;
                this.single_job.job_title = "";
            },
            add_job() {
                if(!nullValues.includes(this.single_job.job_title)){
                    this.$http.post("/job/addJob", {
                        job_title: this.single_job.job_title
                    }).then(({data}) => {
                        this.jobs.push({
                            job_id: data.job_id,
                            job_title: this.single_job.job_title
                        });
                        this.clear_job();
                    }).catch((err) => {
                        showError(err);
                    });
                } else {
                    this.$alert("Enter a job title", "Empty field",  "warning");
                }
            },
            delete_job(job_id) {
                this.$confirm("Are you sure delete this job ?", "", "question").then(() => {
                    this.$http.delete("/job/deleteJob/" + job_id).then(() => {
                        this.jobs = this.jobs.filter(obj => obj.job_id != job_id);
                        this.clear_job();
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            update_job() {
                if(this.single_job.job_id == null){
                    this.$alert("Select a job", "", "warning");
                } else if(nullValues.includes(this.single_job.job_title)){
                    this.$alert("Enter a job title", "", "warning");
                } else {
                    this.$confirm("Are you sure to save changes ?", "", "question").then(() => {
                        document.getElementById("btn_update_job").setAttribute("disabled", "disabled");
                        this.$http.patch("/job/updateJob/" + this.single_job.job_id, {
                            job_title: this.single_job.job_title
                        }).then(() => {
                            let index = this.jobs.findIndex(obj => obj.job_id == this.single_job.job_id);
                            this.jobs[index].job_title = this.single_job.job_title;
                            this.$alert("", "Success", "success");
                        }).catch((err) => {
                            showError(err);
                        }).finally(() => {
                            document.getElementById("btn_update_job").removeAttribute("disabled");
                        });
                    });
                }
            },
            get_cabina(cabina_id) {
                const [cabina] = this.cabinas.filter(obj => obj.cabina_id == cabina_id);
                this.single_cabina.cabina_id = cabina.cabina_id;
                this.single_cabina.cabina_name = cabina.cabina_name;
            },
            clear_cabina() {
                this.single_cabina.cabina_id = null;
                this.single_cabina.cabina_name = "";
            },
            add_cabina() {
                if(!nullValues.includes(this.single_cabina.cabina_name)){
                    this.$http.post("/cabina/addCabina", {
                        cabina_name: this.single_cabina.cabina_name
                    }).then(({data}) => {
                        this.cabinas.push({
                            cabina_id: data.cabina_id,
                            cabina_name: this.single_cabina.cabina_name
                        });
                        this.clear_cabina();
                    }).catch((err) => {
                        showError(err);
                    });
                } else {
                    this.$alert("Enter a cabina name", "", "warning");
                }
            },
            delete_cabina(cabina_id) {
                this.$confirm("Are you sure delete this cabina ?", "", "question").then(() => {
                    this.$http.delete("/cabina/deleteCabina/" + cabina_id).then(() => {
                        this.cabinas = this.cabinas.filter(obj => obj.cabina_id != cabina_id);
                        this.clear_cabina();
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            update_cabina() {
                if(this.single_cabina.cabina_id == null){
                    this.$alert("Select a cabina", "", "warning");
                } else if(nullValues.includes(this.single_cabina.cabina_name)){
                    this.$alert("Enter a cabina name", "", "warning");
                } else {
                    this.$confirm("Are you sure to save changes ?", "", "question").then(() => {
                        document.getElementById("btn_update_cabina").setAttribute("disabled", "disabled");
                        this.$http.patch("/cabina/updateCabina/" + this.single_cabina.cabina_id, {
                            cabina_name: this.single_cabina.cabina_name
                        }).then(() => {
                            let index = this.cabinas.findIndex(obj => obj.cabina_id == this.single_cabina.cabina_id);
                            this.cabinas[index].cabina_name = this.single_cabina.cabina_name;
                            this.$alert("", "Success", "success");
                            this.clear_cabina()
                        }).catch((err) => {
                            showError(err);
                        }).finally(() => {
                            document.getElementById("btn_update_cabina").removeAttribute("disabled");
                        });
                    });
                }
            },
            get_staff(st_id) {
                const [staff] = this.staffs.filter(obj => obj.st_id == st_id);
                this.single_staff.st_id = staff.st_id;
                this.single_staff.en_id = staff.en_id;
                this.single_staff.staff_name = staff.staff_name;
            },
            clear_staff() {
                this.single_staff.st_id = null;
                this.single_staff.en_id = null;
                this.single_staff.staff_name = "";
            },
            add_staff() {
                if(!nullValues.includes(this.single_staff.en_id) && !nullValues.includes(this.single_staff.staff_name)){
                    document.getElementById("btn_add_staff").setAttribute("disabled", "disabled");
                    this.$http.post("/staff/addStaff", {
                        en_id: this.single_staff.en_id,
                        staff_name: this.single_staff.staff_name
                    }).then(({data}) => {
                        this.staffs.push({
                            st_id: data.st_id, 
                            en_id: this.single_staff.en_id,
                            staff_name: this.single_staff.staff_name
                        });
                        this.clear_staff();
                    }).catch((err) => {
                        showError(err);
                    }).finally(() => {
                        document.getElementById("btn_add_staff").removeAttribute("disabled");
                    });
                } else {
                    this.$alert("Fill all required Fields", "Empty Field", "warning");
                }
            },
            delete_staff(st_id) {
                this.$confirm("Are You sure delete this staff ?", "", "question").then(() => {
                    this.$http.delete("/staff/deleteStaff/" + st_id).then(() => {
                        this.staffs = this.staffs.filter(obj => obj.st_id != st_id);
                        this.clear_staff();
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            show_off(st_id) {
                this.$http.patch("/staff/showOff/" + st_id).then(() => {
                    let index = this.staffs.findIndex(obj => obj.st_id == st_id);
                    this.staffs[index].show_staff = 0;
                }).catch((err) => {
                    showError(err);
                });
            },
            show_on(st_id) {
                this.$http.patch("/staff/showOn/" + st_id).then(() => {
                    let index = this.staffs.findIndex(obj => obj.st_id == st_id);
                    this.staffs[index].show_staff = 1;
                }).catch((err) => {
                    showError(err);
                });
            },
            update_staff() {
                if(this.single_staff.st_id == null){
                    this.$alert("Select a staff", "", "warning");
                } else if(!nullValues.includes(this.single_staff.staff_name) && !nullValues.includes(this.single_staff.en_id)){
                    document.getElementById("btn_update_staff").setAttribute("disabled", "disabled");
                    this.$http.patch("/staff/updateStaff/" + this.single_staff.st_id, {
                        en_id: this.single_staff.en_id,
                        staff_name: this.single_staff.staff_name
                    }).then(() => {
                        let index = this.staffs.findIndex(obj => obj.st_id == this.single_staff.st_id);
                        this.staffs[index].staff_name = this.single_staff.staff_name;
                        this.staffs[index].en_id = this.single_staff.en_id;
                        this.$alert("", "Success", "success");
                    }).catch((err) => {
                        showError(err);
                    }).finally(() => {
                        document.getElementById("btn_update_staff").removeAttribute("disabled");
                    });
                } else {
                    this.$alert("Fill all required Fields", "Empty Field", "warning");
                }
            },
            create_list_rest_day() {
                if(['A', 'U'].includes(this.user.type)){
                    if(!nullValues.includes(this.list_rest_days.work_date)){
                        this.$confirm("Are you sure create list for date " + this.list_rest_days.work_date + " ?", "", "question").then(() => {
                            document.getElementById("waiting").style.display = "block";
                            document.getElementById("btn_rest_day").setAttribute("disabled", "disabled");
                            var d = new Date();
                            d.setHours(d.getHours() + 3)
                            this.$http.post("/daily_staff_list/createRestList", { 
                                work_date: this.list_rest_days.work_date,
                                user: this.user.username,
                                datetime_log: d
                            }).then(() => {
                                this.$alert("List successfully created", "Success", "success");
                            }).catch((err) => {
                                showError(err);
                            }).finally(() => {
                                document.getElementById("waiting").style.display = "none";
                                document.getElementById("btn_rest_day").removeAttribute("disabled");
                            });
                        });
                    } else {
                        this.$alert("Select a work date to create list", "", "warning");
                    }
                }
            },
            search_staff_log() {
                if(!nullValues.includes(this.staff_log_search_value)){
                    this.$http.post("/index/searchStaffLogChange", {
                        search_value: this.staff_log_search_value
                    }).then(({data}) => {
                        this.staff_change_log = data;
                    });
                } else {
                    this.$alert("Empty search field", "Empty Field", "warning");
                }
            },
            search_transfered_employees() {
                this.transfered_employees.employees = [];
                if(this.transfered_employees.old_st_id){
                    this.$http.get('/attendance/getTransferedEmployees/' + this.transfered_employees.year + '/' + this.transfered_employees.month + '/' + this.transfered_employees.old_st_id).then(({data}) => {
                        this.transfered_employees.employees = data;
                    });
                } else {
                    this.transfered_employees.employees = [];
                }
            },
            active_log_search() {
                if(!nullValues.includes(this.active_log_search_value)){
                    this.$http.post("/index/searchActiveLogChange", {
                        search_value: this.active_log_search_value
                    }).then(({data}) => {
                        this.active_change_log = data;
                    });
                } else {
                    this.$alert("Empty search field", "Empty Field", "warning");
                }
            },
            get_list_details(dsl_id, st_id, datetime) {
                this.dsl_id = dsl_id;
                this.st_id = st_id;
                this.datetime = new Date(datetime).toISOString().split('T')[0] + ' ' + new Date(datetime).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'})
            },
            get_list_history(dsl_id) {
                this.dsl_id = dsl_id;
            },
            isValidDate(d) {
                let date = new Date(d);
                return date instanceof Date && !isNaN(date);
            },
            diff_days(d) {
                var date1 = new Date();
                var date2 = new Date(d);
                
                // To calculate the time difference of two dates
                var Difference_In_Time = date2.getTime() - date1.getTime();
                
                // To calculate the no. of days between two dates
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                return Math.round(Difference_In_Days);
            },
            
            diff_days_between_two_dates(d1, d2) {
                var date1 = new Date(d1);
                var date2 = new Date(d2);
                
                // To calculate the time difference of two dates
                var Difference_In_Time = date2.getTime() - date1.getTime();
                
                // To calculate the no. of days between two dates
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                return Math.round(Difference_In_Days);
            },
            saveStaffSort() {
                this.$http.post('/staff/saveStaffSort', {
                    list: this.staff_to_sort
                }).then(() => {
                    this.$alert('Successfully', "Success", "success");
                }).catch((err) => {
                    showError(err);
                })
            },
            updateSpecialStaff(st_id, special_staff) {
                this.$http.post('/staff/updateSpecialStaff/' + st_id, {
                    special_staff
                }).then(() => {
                    
                }).catch((err) => {
                    showError(err);
                })
            },
            printExpirePassports() {
                this.printExpires = true;
                setTimeout(() => {
                    window.print();
                    this.printExpires = false;
                }, 100);
            },
            printExpireAccomodations() {
                this.printExpiresAccomodation = true;
                setTimeout(() => {
                    window.print();
                    this.printExpiresAccomodation = false;
                }, 100);
            },
            printDailyActivityData() {
                this.printDailyActivity = true;
                setTimeout(() => {
                    window.print();
                    this.printDailyActivity = false;
                }, 100);
            }
        }, 
        computed: {
            filtered_jobs() {
                return this.jobs.filter(obj => {
                    return obj.job_id == this.job_search || obj.job_title.match(this.job_search);
                });
            },
            filtered_cabinas() {
                return this.cabinas.filter(obj => {
                    return obj.cabina_id == this.cabina_search || obj.cabina_name.match(this.cabina_search);
                });
            },
            filtered_staffs() {
                return this.staffs.filter(obj => {
                    return (obj.st_id == this.staff_search ||
                           obj.en_id == this.staff_search ||
                           obj.staff_name.match(this.staff_search)) && obj.special_staff == this.showSpecialStaffs
                });
            },
            filtered_list_detail() {
                return this.list_details.filter(obj => obj.dsl_id == this.dsl_id).sort((a, b) => a.sort_code - b.sort_code);
            },
            filtered_expired_passports() {
                return this.expired_passports.filter(obj => {
                    return this.st_id_for_expired ? ( obj.st_id == this.st_id_for_expired) : true
                });
            },
            filtered_expired_accomodations() {
                return this.expired_accomodations.filter(obj => {
                    return this.st_id_for_expired_accomodation ? ( obj.st_id == this.st_id_for_expired_accomodation) : true
                });
            },
            filtered_daily_activities() {
                const rows_by_date_warning =  this.daily_activity_rows.filter(item => this.warning_dates ?  this.diff_days_between_two_dates(item.work_date, item.datetime_log) >= 4 || ((this.diff_days_between_two_dates(item.work_date, item.datetime_log) - 1) < 0  && new Date(item.datetime_log).toISOString().split('T')[0] != new Date(item.work_date).toISOString().split('T')[0]) : true)
                return this.daily_activity_user ? rows_by_date_warning.filter(obj => obj.user == this.daily_activity_user).filter(obj => this.except_daily_activity_user ? obj.user != 'حەکیم عومەر' : true) : rows_by_date_warning.filter(obj => this.except_daily_activity_user ? obj.user != 'حەکیم عومەر' : true)
            }
        },
        filters: {
            absent_filter(value, language) {
                return [
                    indexLanguage[language].present,
                    indexLanguage[language].absent,
                    indexLanguage[language].off
                ][value];
            },
            country_filter(value) {
                return {
                    "1": "Iraq",
                    "2": "Foreign",
                    "3": "Iran",
                    "4": "Bangladish",
                    "5": "Syria",
                    "6": "India",
                    "7": "Pakistan",
                }[value]
            },
            status_filter(value) {
                return {
                    "0": "Deactived", 
                    "1": "Actived" 
                }[value]
            }
        },
        watch: {
            list_date(value){
                this.$http.post("/daily_staff_list/getDailyList", {
                    work_date: value
                }).then(({data}) => {
                    this.daily_list = data.lists;
                    this.list_details = data.list_details;
                    this.history_rows = data.list_history;
                    this.all_rows = data.all_rows;
                });
                this.$http.post("/daily_staff_list/getFoods", {
                    work_date: value
                }).then(({data}) => {
                    this.staff_foods = data.staff_foods;
                    this.total_foods = data.total_foods;
                });
            },
            daily_activity_from(value) {
                if(value) {
                    this.daily_activity_rows = []
                    this.daily_activity_users = []
                    this.$http.get('/daily_staff_list/getLogViewByDate/' + this.daily_activity_from + '/' + this.daily_activity_to).then(({data}) => {
                        this.daily_activity_rows = data.rows
                        this.daily_activity_users = data.users.map(obj => obj.user)
                    })
                } else {
                    this.daily_activity_rows = []
                    this.daily_activity_users = []
                }
            },
            daily_activity_to(value) {
                if(value) {
                    this.daily_activity_rows = []
                    this.daily_activity_users = []
                    this.$http.get('/daily_staff_list/getLogViewByDate/' + this.daily_activity_from + '/' + this.daily_activity_to).then(({data}) => {
                        this.daily_activity_rows = data.rows
                        this.daily_activity_users = data.users.map(obj => obj.user)
                    })
                } else {
                    this.daily_activity_rows = []
                    this.daily_activity_users = []
                }
            },
            'transfered_employees.old_st_id': function(){
                this.search_transfered_employees();
            },
            'transfered_employees.year': function(){
                this.search_transfered_employees();
            },
            'transfered_employees.month': function(){
                this.search_transfered_employees();
            },
            expire_type(value) {
                if(value == 'less_than_1_and_half_year') {
                    this.$http.post("/expirePassport/getOneAndHalfYearData").then(({data}) => {
                        this.expired_passports = data;
                    });
                } else if(value == 'less_than_1_year') {
                    this.$http.post("/expirePassport/getOneYearData").then(({data}) => {
                        this.expired_passports = data;
                    });
                } else if(value == 'less_than_6_month') {
                    this.$http.post("/expirePassport/getSixMonthData").then(({data}) => {
                        this.expired_passports = data;
                    });
                } else if(value == 'less_than_2_month') {
                    this.$http.post("/expirePassport/getData").then(({data}) => {
                        this.expired_passports = data;
                    });
                } else if(value == 'all') {
                    this.$http.post("/expirePassport/getAll").then(({data}) => {
                        this.expired_passports = data;
                    });
                }
            },
            expire_type_accomodation(value) {
                if(value == 'less_than_6_month') {
                    this.$http.post("/expireAccomodation/getSixMonthData").then(({data}) => {
                        this.expired_accomodations = data;
                    });
                } else if(value == 'less_than_2_month') {
                    this.$http.post("/expireAccomodation/getData").then(({data}) => {
                        this.expired_accomodations = data;
                    });
                } else if(value == 'all') {
                    this.$http.post("/expireAccomodation/getAll").then(({data}) => {
                        this.expired_accomodations = data;
                    });
                }
            },
            showSPecialStaffsForSort(value) {
                this.staff_to_sort = this.staffs.filter(obj => obj.special_staff == value && obj.show_staff == 1).sort((a, b) => a.staff_sort_code - b.staff_sort_code)
            }
        }
    }
</script>


<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 12pt;
        color: black;
        font-weight: 500;
    }
    .table-only-print tbody tr td {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .table-only-print {
        display: none;
    }
    @media print {
        #content-wrapper {
            background-color: white !important;
        }
        .no-print, #nav {
            display: none;
        }
        .table-only-print {
            display: table !important;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .expired-passport-date.bg-danger {
            background-color: #e74a3b !important;
        }
        .expired-passport-date.bg-warning {
            background-color: #f6c23e !important;
        }
        .expired-passport-date.bg-success {
            background-color: #1cc88a !important;
        }
        .modal-content {
            width: 200%;

        }
        .modal-dialog {
            margin-inline: 0 !important;
            margin-inline-start: 10px !important;
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>
